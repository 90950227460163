import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {

    namespaced: true,
    state() {
        return {
            all: [],
            tvProfiles: [],
            radioProfiles: [],
            categories: []
        };
    },

    actions: {
        deleteMarketFile({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/delete-mpgp-product-instance", {ids: id},
                        { headers: authHeader() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        },
        getSingleMarketFile({ commit }, params) {
            axios
                .get('/product-instance/' + params, { headers: authHeader() })
                .then((response) => commit('setView', response.data));
        },
        getMarketCategories({ commit }, data) {
            axios
                .get("instance-categories", { params: data, headers: authHeader() })
                .then(response => {
                    commit('setCategories', response.data);
                })
                .catch(error => (reject(error)));
        },
        getMarketFiles({ commit }, data) {
            axios
                .get("market-product-instances", { params: data, headers: authHeader() })
                .then(response => (commit('setAll', response.data)))

        },
        getGovernanceFiles({ commit }, data) {
            return axios
                .get("governance-product-instances", { params: data, headers: authHeader() })
                .then(response => commit('setAll', response.data))
                .catch(error => { throw error });
        },
        getRadioProfiles({ commit }) {
            axios
                .get("/market-profiles/radio", { headers: authHeader() })
                .then(response => (commit('setRadioProfile', response.data)))
        },
        getTVProfiles({ commit }) {
            axios
                .get("/market-profiles/tv", { headers: authHeader() })
                .then(response => (commit('setTvProfile', response.data)))
        },
    },
    mutations: {
        setAll(state, data) {
            state.all = data.data
        },
        setRadioProfile(state, data) {
            state.radioProfiles = data
        },
        setTvProfile(state, data) {
            state.tvProfiles = data
        },
        setView(state, data) {
            state.view = data.data;
        },
        setCategories(state, data) {
            state.categories = data.data;
        },
    }
}