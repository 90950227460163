import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
  namespaced: true,
  state() {
    return {
      all: [],
      instanceFiles: [],
    };
  },

  actions: {
    dpScheduleList({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .post("/dp-dashboard/list", search, { headers: authHeader() })
          .then((response) => commit('setAll', response.data));
      });
    },
    dpInstanceFiles({ commit }, id) {
      axios
        .get("/dp-instance-files/" + id, { headers: authHeader() })
        .then((response) => commit('setInstanceFiles', response.data));
    },
    addSchedule({ commit }, schedule) {

      return new Promise((resolve, reject) => {
        axios
          .post("/dp-schedules", schedule, { headers: authHeader() })
          .then(response => { resolve(response.data) })
          .catch(error => (reject(error)));
      })
    },
    updateSchedule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/dp-schedules/edit', payload, { headers: authHeader() })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },


  mutations: {
    setAll(state, data) {
      state.all = data.data;
    },
    setInstanceFiles(state, data) {
      state.instanceFiles = data.data;
    },
  },
};
