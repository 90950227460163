import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/store';

function loadView(view) {
  return () => import(`../components/${view}.vue`);
}

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'dashboard-view',
    component: loadView('DefaultContainer'),
    meta: { auth: true },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard-view',
        component: loadView('Dashboard'),
      },
      {
        path: '/edit-my-account',
        name: 'edit-profile',
        component: loadView('User/EditMyAccount'),
      },
      {
        path: '/my-account',
        name: 'view-profile',
        component: loadView('User/MyAccount'),
      },
      {
        path: '/users',
        name: 'list-users',
        component: loadView('User/ViewUsers'),
        meta: { auth: true },
      },
      {
        path: '/add-user',
        name: 'create-users',
        component: loadView('User/AddUser'),
        meta: { auth: true },
      },
      {
        path: '/edit-user/:id',
        name: 'edit-users',
        component: loadView('User/EditUser'),
      },
      {
        path: '/clients',
        name: 'list-clients',
        component: loadView('Client/ViewClients'),
      },
      {
        path: '/add-client',
        name: 'create-clients',
        component: loadView('Client/AddClient'),
      },
      {
        path: '/detail-client/:id',
        name: 'edit-clients',
        component: loadView('Client/DetailClients'),
      },
      {
        path: '/create-product',
        name: 'create-products',
        component: loadView('Products/CreateProduct'),
      },
      {
        path: '/view-product',
        name: 'list-products',
        component: loadView('Products/ViewProducts'),
      },
      {
        path: '/pending-users',
        name: 'list-pending-users',
        component: loadView('User/PendingUsers'),
      },

      {
        path: '/upload-media',
        name: 'create-instance',
        component: loadView('Media/UploadMedia'),
      },
      {
        path: '/view-product-instances',
        name: 'view-instance',
        component: loadView('Media/ViewProductInstances'),
      },
      {
        path: '/edit-product-instance/:id',
        name: 'edit-instance',
        component: loadView('Media/EditProductInstance'),
      },
      {
        path: '/view-product-instance/:id',
        name: 'show-instance',
        component: loadView('Media/ViewProductInstance'),
      },
      {
        path: '/market',
        name: 'market-list',
        component: loadView('PolicyAndMarketPopulation/MarketsAndPopulation'),
      },
      {
        path: '/governance',
        name: 'governance-list',
        component: loadView('PolicyAndMarketPopulation/GovernanceAndPolicy'),
      },
      {
        path: '/measured-market',
        name: 'measured-market',
        component: loadView(
          'PolicyAndMarketPopulation/DataReleaseDate&MeasuredMarket'
        ),
      },
      {
        path: '/market-profile',
        name: 'market-profile',
        component: loadView('PolicyAndMarketPopulation/MarketProfileRadio&TV'),
      },
      {
        path: '/tv-profile',
        name: 'tv-profile',
        component: loadView('PolicyAndMarketPopulation/TVProfiles'),
      },
      {
        path: '/radio-profile',
        name: 'radio-profile',
        component: loadView('PolicyAndMarketPopulation/RadioProfiles'),
      },
      {
        path: '/help',
        name: 'help-email',
        component: loadView('Help/Help'),
      },
      {
        path: '/client-upload-tour',
        name: 'video-tour-write',
        component: loadView('UploadClientSiteTour/UploadClientSiteTour'),
      },
      {
        path: '/app-notification',
        name: 'app-notification-create',
        component: loadView('Notification/AddNotification'),
      },
      {
        path: '/app-notification-update/:id',
        name: 'app-notification-update',
        component: loadView('Notification/EditNotification'),
      },
      {
        path: '/app-notification-list',
        name: 'app-notification-list',
        component: loadView('Notification/ViewNotification'),
      },
      {
        path: '/notification-heading/:id',
        name: 'app-notification-view',
        component: loadView('Notification/NoticationHeading'),
      },
      {
        path: '/add-banner',
        name: 'banner-create',
        component: loadView('Banner/AddBanner'),
      },
      {
        path: '/banner-update/:id',
        name: 'banner-update',
        component: loadView('Banner/EditBanner'),
      },
      {
        path: '/banner-list',
        name: 'banner-list',
        component: loadView('Banner/ViewBannerList'),
      },
      {
        path: '/client-export',
        name: 'export-clients',
        component: loadView('Client/Export'),
      },
      {
        path: '/user-export',
        name: 'export-users',
        component: loadView('User/ExportUser'),
      
      },
      {
        path: '/have-question',
        name: 'have-question',
        component: loadView('Auth/HaveAQuestionAuthenticated'),
      
      },
      {
        path: '/contact',
        name: 'contact',
        component: loadView('Auth/HaveAQuestionAuthenticated'),
      
      },
      {
        path: '/signout-notification',
        name: 'signout-write',
        component: loadView('Notification/SignOutNotification'),
        meta: { auth: false },
      },
      {
        path: '/:lang/vam/:slug',
        name: 'vam-dashboard',
        component: loadView('Reports/VAMReport'),
        meta: { auth: true }
      },
      {
        path: '/:lang/etam/:slug',
        name: 'etam-dashboard',
        component: loadView('Reports/ETAMReport'),
        meta: { auth: true }
      },
      {
        path: '/dashboard-stats',
        name: 'dp-schedule-dashboard',
        component: loadView('StatisticDashboard/StatisticDashboard'),
        meta: { auth: true }
      },
      {
        path: '/view-dashboard-schedules',
        name: 'dp-schedule-view',
        component: loadView('StatisticDashboard/ViewDashboardSchedules'),
        meta: { auth: true }
      },
      {
        path: '/upload-schedules',
        name: 'dp-schedule-upload',
        component: loadView('StatisticDashboard/UploadSchedules'),
        meta: { auth: true }
      },
      {
        path: '/create-schedule',
        name: 'dp-schedule-create',
        component: loadView('StatisticDashboard/CreateSchedule'),
      },
    ],
  },
  {
    path: '/login',
    name: 'logn',
    component: loadView('Auth/Login'),
    meta: { auth: false },
  },
  
  {
    path: '/:lang/have-a-question',
    name: 'have-a-question',
    component: loadView('Auth/HaveAQuestion'),
  },
  {
    path: '/:lang/contact-us',
    name: 'contact-us',
    component: loadView('Auth/HaveAQuestion'),
  },
 
  {
    path: '/thankyou',
    name: 'thankyou',
    component: loadView('User/ThankyouMessage'),
  },
  {
    path: '/:lang/invalidToken',
    name: 'invalidToken',
    component: loadView('InvalidTokenPage'),
    meta: { auth: false },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: loadView('Auth/Logout'),
    meta: { auth: false },
  },
  
  {
    path: '/:lang/forgot-password',
    name: 'ForgotPassword',
    component: loadView('Auth/ForgotPassword'),
    meta: { auth: false },
  },
  {
    path: '/:lang/reset-password',
    name: 'ResetPassword',
    component: loadView('Auth/ResetPassword'),
    meta: { auth: false },
  },
  {
    path: '/:lang/invite/accept/:code',
    name: 'ManualUser',
    component: loadView('CreateManualUser'),
    meta: { auth: false },
  },
  {
    path: '/error-403',
    name: 'Error403',
    component: loadView('Auth/Error403'),
    meta: { auth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error403',
    component: loadView('Auth/Error403'),
    meta: { auth: false },
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.state.auth.loggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});
export default router;
